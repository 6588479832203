import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import { StaticImage } from 'gatsby-plugin-image'
import s1 from '../images/pragicts-software-development-process.png'
const WebsiteDeliveryScope = () => {
    return (
        <Layout pageTitle="Website Content Capture Questionnair" og_desc="">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>WEBSITE CONTENT <br/>
<span> CAPTURE QUESTIONNAIR</span></h2>
                                <p></p>
                            
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>QUESTIONNAIR</div>
                        <div className="container main-about">
                          


                            
                        
                            
                           <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ001&nbsp;&nbsp;</span>Do you already have a website?<br/>If yes, please specify the URL </h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ002&nbsp;&nbsp;</span>If you already have a website, why do you want a new one?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ003&nbsp;&nbsp;</span>Why do you want a website?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ004&nbsp;&nbsp;</span>What are you trying to achieve (please quantify) in terms of your business?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ005&nbsp;&nbsp;</span>Name of the company?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ006&nbsp;&nbsp;</span>Business registration name of the company?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ007&nbsp;&nbsp;</span>Address of the company?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ008&nbsp;&nbsp;</span>Primary email address of the company to contact?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ009&nbsp;&nbsp;</span>Primary phone number of the company to contact?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ010&nbsp;&nbsp;</span>Secondary email addresses of the company to contact?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ011&nbsp;&nbsp;</span>Secondary phone numbers of the company to contact?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ012&nbsp;&nbsp;</span>Year of incorporation / Date of incorporation?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ013&nbsp;&nbsp;</span>Nature/type of business?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ014&nbsp;&nbsp;</span>Why should customers select you?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ015&nbsp;&nbsp;</span>Identify the key points that make you unique or different?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ016&nbsp;&nbsp;</span>Who is your immediate competition?<br/>List them<br/>- Name of the company<br/>- Website URL</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ017&nbsp;&nbsp;</span>Who is the top 5 companies in your country or markets you are in?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ018&nbsp;&nbsp;</span>Do you want to get there, If yes then list what is takes to get there?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ019&nbsp;&nbsp;</span>Where do you expect to take your company in the next 05 - 10 years?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ020&nbsp;&nbsp;</span>Where are your markets (beyond shores)?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ021&nbsp;&nbsp;</span>What services are offered? <br/>For each service<br/>- Title<br/>- Description</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ022&nbsp;&nbsp;</span>What products are offered?  <br/>For each Product<br/>- Title<br/>- Description</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ023&nbsp;&nbsp;</span>Key milestones/achievements of the company?  <br/>- Year<br/>- Title<br/>- Description</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ024&nbsp;&nbsp;</span>Are there any global or local certifications your company has achieved?   <br/>If Yes, list them</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ025&nbsp;&nbsp;</span> Are there any global or local certifications you are planning to get?    <br/>If Yes, list them</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ026&nbsp;&nbsp;</span>What is your total staff count?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ027&nbsp;&nbsp;</span>How many of them are IT users (people who use a computing device at work)?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ028&nbsp;&nbsp;</span>Branding required (Y/N)?  <br/>- Logo<br/>- Tagline</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ029&nbsp;&nbsp;</span>Does your company have social platform accounts (LinkedIn, YouTube, Instagram, FB, TikTok, etc.)?   <br/>If Yes, list them with the addresses</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ030&nbsp;&nbsp;</span>Do you have new and events on your company which you want to share?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ031&nbsp;&nbsp;</span>Do you advertise recruitments for your company?</h6>
                          </p>
                          <p><h6 style={{fontSize: '13px'}}><span style={{color: 'black',fontWeight:'bold'}}>WCCQ032&nbsp;&nbsp;</span>Do you have CSR (Corporate Social Responsbility) initiatives?</h6>
                          </p>
                          



<div>










</div> 





















                        </div>
                     
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                        
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default WebsiteDeliveryScope
